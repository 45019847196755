import React from 'react';

function LightModeIcon() {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.37617 0.143901C8.94384 0.336077 8.65151 0.679515 8.47313 1.01602L9.37617 0.143901Z"
                fill="#777E90"
            ></path>
            <path
                d="M8.47313 1.01602C8.12705 1.66891 8.064 2.56609 8.44871 3.32585L8.47313 1.01602Z"
                fill="#777E90"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0438 8.3471C16.0438 4.72096 13.8994 1.59777 10.8138 0.172637C10.3467 -0.0431437 9.8373 -0.0610764 9.37617 0.143901L8.47313 1.01602L8.44871 3.32585C8.82946 4.07791 9.04384 4.93404 9.04384 5.8471C9.04384 8.8847 6.58136 11.3471 3.54383 11.3471C3.26371 11.3471 2.98902 11.3262 2.7211 11.2861C1.88063 11.1602 1.05107 11.5 0.54231 12.0362C0.280138 12.3125 0.0491009 12.699 0.00642557 13.1698C-0.0389898 13.6709 0.141212 14.1451 0.491722 14.5172C2.13169 16.2581 4.46138 17.3471 7.04384 17.3471C12.0144 17.3471 16.0438 13.3177 16.0438 8.3471ZM10.1861 2.18414C10.1835 2.28251 10.2039 2.36482 10.233 2.42229C10.7544 3.45216 11.0438 4.61761 11.0438 5.8471C11.0438 9.9893 7.68591 13.3471 3.54383 13.3471C3.1641 13.3471 2.79042 13.3188 2.42493 13.2641C2.36076 13.2545 2.27731 13.2612 2.18744 13.2937C2.16323 13.3024 2.14074 13.3123 2.12009 13.3227C3.38576 14.5753 5.12394 15.3471 7.04384 15.3471C10.9099 15.3471 14.0438 12.2131 14.0438 8.3471C14.0438 5.61615 12.4799 3.24852 10.1949 2.0945C10.1902 2.12225 10.187 2.15231 10.1861 2.18414Z"
                fill="#777E90"
            ></path>
        </svg>
    );
}

export default LightModeIcon;